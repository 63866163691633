.items {
  margin-bottom: 1rem;
}

.button {
  margin-right: 10px;
  background: gray;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 120px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #ccc;
  margin-bottom: 10px;
}

.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}