.galery{
    display:flex;
    justify-content: center;
    margin-left: 20px;
    margin-top: 20px;
}

.pager {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.items { 
    margin-bottom: 1rem;
}